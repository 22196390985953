class PodioWebform extends HTMLElement {
  constructor() {
    super();
  }
  connectedCallback() {
    this.setContent();
  }
  attributeChangedCallback() {
    this.setContent();
  }
  static get observedAttributes() {
    return ["url"];
  }

  // Our function to set the textContent based on attributes.
  setContent() {
    if (this.innerHTML !== "") {
      return;
    }
    const url = this.getAttribute("url");
    const id = url.substr(url.lastIndexOf("/") + 1);

    const onload = () => {
      document.oldWrite = document.write;
      document.write = (str) => {
        this.innerHTML = str;
        document.write = document.oldWrite;
      };

      var s = document.createElement("script");
      s.type = "text/javascript";
      s.textContent = `_podioWebForm.render("${id}")`;
      this.appendChild(s);
    };

    var fragment = new DocumentFragment();

    var s = document.createElement("script");
    s.src = url + ".js";
    s.onload = onload;

    fragment.appendChild(s);

    this.appendChild(fragment);

    // 2. Onload function should add the render call to this.innerHtml
  }
}

export default PodioWebform;

if (!window.customElements.get("se-podio-webform")) {
  window.PodioWebform = PodioWebform;
  window.customElements.define("se-podio-webform", PodioWebform);
}

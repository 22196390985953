/** @typedef {{load: (Promise<unknown>); flags: (unknown)}} ElmPagesInit */

import Headroom from "headroom.js";
import "./components/podio-webform.js";
import constants from "./generated/constants.json";

// Cookiepro
// preload cookiepro script on site generation OR in runtime in dev mode
// 2. Add Cookiepro scripts
(function (w, d, s, i) {
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    src = `//cookie-cdn.cookiepro.com/consent/${i}/OtAutoBlock.js`;
  j.src = src;
  f.parentNode.insertBefore(j, f);

  var k = d.createElement(s);
  k.src = "//cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js";
  k.charset = "UTF-8";
  k.dataset.domainScript = i;

  f.parentNode.insertBefore(k, f);

  // About OptanonWrapper: https://community.cookiepro.com/s/article/UUID-29158b4e-22f6-0067-aa36-94f3b8cf3561
  w.OptanonWrapper = function () {};
})(window, document, "script", constants.cookieproKey);
// End Cookiepro

// Google Tag Manager
(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "//www.googletagmanager.com/gtm.js?id=" + i + dl;
  j.type = "text/plain";
  j.className = "optanon-category-C0002";
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", constants.gtmKey);
// End Google Tag Manager

/** @type ElmPagesInit */
export default {
  load: async function (elmLoaded) {
    const app = await elmLoaded;

    window.OptanonWrapper = function () {
      // feed new consent data to Elm app
      if (
        app.ports &&
        app.ports.onCookieConsentChange &&
        window.Optanon &&
        window.OptanonActiveGroups
      ) {
        app.ports.onCookieConsentChange.send({
          allGroups: window.Optanon.GetDomainData().Groups,
          activeGroupIdsString: OptanonActiveGroups,
        });
      }
    };

    document.body.addEventListener("click", function (ev) {
      if (
        ev.target.classList.contains("se-custom-ot-sdk-show-settings") &&
        window.OneTrust
      ) {
        OneTrust.ToggleInfoDisplay();
      }

      if (
        ["carousel__next", "carousel__prev"].some((className) =>
          ev.target.classList.contains(className)
        )
      ) {
        document.getElementById(ev.target.getAttribute("rel"))?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    });

    new Headroom(document.querySelector("header")).init();
  },
  flags: function () {},
};
